* {
    -webkit-tap-highlight-color: transparent;
}

:focus {
    outline: none;
}

::-moz-focus-inner {
    border: 0;
}

@font-face {
    font-family: 'Fontin';
    font-weight: normal;
    font-style: normal;
    src: url(http://media.oac-live.com/web/online/fonts/Fontin-Regular.ttf) format("truetype");
}

html, body, ul, li, h1, h2, h3, h4, h5, h6, table, tbody, tr, td {
    margin: 0;
    padding: 0;
}

body {
    background: #000000;
    font-family: Fontin, Fontin Regular, Font CN, Font JP, Arial, Tahoma;
    font-size: 12px;
    color: #ffffff;
}

table {
    border-collapse: collapse;
}

ul, li {
    list-style: outside none none;
}

a, a:hover, a:active {
    color: #ffffff;
    text-decoration: none;
}

.wrapper {
    margin: 0 auto;
}
.navigation.content_list_item > .list_item > a > span.ui_companions {
    background-image: url("http://media.oac-live.com/web/redemption/ui/icons/userinterface/igm_pet.png");
}

.navigation.content_list_item > .list_item > a > span.ui_cards {
    background-image: url("http://media.oac-live.com/web/redemption/ui/icons/userinterface/igm_card.png");
}

.navigation.content_list_item > .list_item > a > span.ui_guild {
    background-image: url("http://media.oac-live.com/web/redemption/ui/icons/userinterface/igm_guild.png");
}
.ui_button {
    background: #777 url(/img/button_gray.png) no-repeat 0 0;
    background-size: 100% 100%;
    border: 2px #888 outset;
    cursor: pointer;
    text-shadow: 1px 0 1px rgba(0, 0, 0, 0.2), 1px 1px 1px rgba(0, 0, 0, 0.2), 0 2px 5px rgba(0, 0, 0, 0.7);
}

.ui_button > a {
    width: 100%;
    height: 100%;
    display: block;
    background-size: 100% 100%;
}

.ui_button:hover {
    background-color: #222;
    border: 2px #444 outset;
}

.ui_button_selected {
    background-color: #222;
    border: 2px #444 outset;
}
.hidden {
    display: none !important;
}
